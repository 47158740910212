

































































import { Vue, Component } from 'vue-property-decorator'

@Component({
    name: 'Gallery'
})
export default class extends Vue {
    activeIndex: number = 0

    get menus() {
        return [
            {
                name: this.$t('mindset'),
                path: require('@/assets/images/mindset.png')
            },
            {
                name: this.$t('housework'),
                path: require('@/assets/images/housework.png')
            },
            {
                name: this.$t('cooking') ,
                path: require('@/assets/images/cooking.png')
            },
            {
                name: this.$t('careGiver'),
                path: require('@/assets/images/careGiver.png')
            },
            {
                name: this.$t('communication'),
                path: require('@/assets/images/Communication.png')
            },
        ]
    }
}
